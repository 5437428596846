.a1yepc47{color:inherit;-webkit-text-decoration:none;text-decoration:none;}.a1yepc47:hover svg{opacity:1;-webkit-transform:none;-ms-transform:none;transform:none;visibility:visible;--visibility-delay:0s;}
.calmbc7{display:inline-block;margin-left:6px;color:var(--timvir-secondary-text-color);height:0.9rem;width:0.9rem;vertical-align:middle;-webkit-transition:opacity 0.2s,-webkit-transform 0.2s,visibility 0s var(--visibility-delay,0.2s);-webkit-transition:opacity 0.2s,transform 0.2s,visibility 0s var(--visibility-delay,0.2s);transition:opacity 0.2s,transform 0.2s,visibility 0s var(--visibility-delay,0.2s);opacity:0;visibility:hidden;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);}
.hryzf2k{margin-top:3rem;margin-bottom:1rem;font-size:2rem;line-height:1.125;font-weight:590;text-indent:-0.05em;}
.h1r910l8{position:relative;margin:2.5rem 0 1rem;font-size:1.5rem;line-height:1.1666;font-weight:590;}
.hmqldzl{position:relative;margin:1rem 0 1rem;font-size:1.0625rem;line-height:1.4705882353;font-weight:590;}
.hpw7qeo{position:relative;margin:1rem 0 1rem;font-size:0.9375rem;line-height:1.4375;font-weight:590;}
.b1gfctk0{margin-left:0;font-size:1.1rem;}.b1gfctk0 > *:first-child{margin-top:0;}.b1gfctk0 > *:last-child{margin-bottom:0;}
.h1kfhyug{display:block;border:none;height:1px;width:100%;background:currentColor;opacity:0.25;}
.t11kzh9g{border-spacing:0;border-collapse:collapse;width:100%;overflow:auto;}


.t1ichb2u{background-color:#fff;border-top:1px solid var(--c-p-2);}.t1ichb2u:nth-child(2n){background-color:var(--c-p-0);}
.tynrygb{text-align:var(--tynrygb-0);padding:6px 13px;border:1px solid var(--c-p-2);}
.ta24p8f{text-align:var(--ta24p8f-0);padding:6px 13px;border:1px solid var(--c-p-2);}
.ctk2u2p{border-radius:5px;padding:4px 6px 3px;font-size:0.8em;background:var(--timvir-secondary-background-color);border:1px solid var(--timvir-border-color);}
.az92cdb{color:currentColor;-webkit-text-decoration:none;text-decoration:none;background-image:linear-gradient(transparent,transparent 5px,#383838 5px,#383838);background-position:bottom;background-size:100% 6px;background-repeat:repeat-x;}.az92cdb:hover{background-image:linear-gradient(transparent,transparent 3px,#2bbc8a 3px,#2bbc8a);}.az92cdb:hover .ctk2u2p{box-shadow:inset 0 0 0 1px rgba(16,22,26,0.5),inset 0 1px 4px rgba(16,22,26,0.2);}.az92cdb:active .ctk2u2p{box-shadow:inset 0 0 0 1px rgba(16,22,26,0.7),inset 0 1px 4px rgba(16,22,26,0.4);background:var(--c-p-2);}



