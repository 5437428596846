.h17eoe18{padding:0 var(--timvir-page-margin);height:3rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}@media (min-width:48rem){.h17eoe18{padding-top:24px;display:block;height:auto;}}
.d1mgn6rc{font-size:0.875rem;line-height:1.3125;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:16px;}
.d7bx8iu{font-weight:590;}
.d119ou0k{background-color:var(--timvir-border-color);width:1px;height:1.25rem;}
.d1f74255{-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;-webkit-transition:all 0.16s;transition:all 0.16s;margin-top:16px;display:none;}@media (min-width:48rem){.d1f74255{display:block;}}
.l5l3c45{border-bottom:1px solid var(--timvir-border-color);padding:0 var(--timvir-page-margin);height:3rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer;}@media (min-width:48rem){.l5l3c45{display:none;}}
.c15ju3pv{display:block;width:1.3em;height:1.3em;margin-right:8px;min-width:1.3em;}
.s2bwnbz{margin-left:auto;}
.iu06l76{display:none;}
.s56darb{overflow-y:scroll;position:fixed;top:0px;width:100%;}
.rwbgl5x{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}@media (min-width:48rem){.rwbgl5x{height:100%;}}
.c15aig6x{display:none;background-color:var(--timvir-background-color);}#menu:checked ~ .c15aig6x{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;position:fixed;top:6rem;left:0;right:0;bottom:0;}@media (min-width:48rem){.c15aig6x{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;height:100%;position:static;overflow:hidden;}}
.svou0q5{padding:24px 0;overflow-y:auto;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;overscroll-behavior:auto;-webkit-scroll-padding-block:24px;-moz-scroll-padding-block:24px;-ms-scroll-padding-block:24px;scroll-padding-block:24px;-webkit-mask-image:linear-gradient( to bottom, transparent 0%, rgba(0,0,0,0.2) 12px, #000 24px, #000 calc(100% - 24px), rgba(0,0,0,0.2) calc(100% - 12px), transparent 100% );mask-image:linear-gradient( to bottom, transparent 0%, rgba(0,0,0,0.2) 12px, #000 24px, #000 calc(100% - 24px), rgba(0,0,0,0.2) calc(100% - 12px), transparent 100% );}
.n1ibjf0n{padding-inline:calc(var(--timvir-page-margin) - 8px);}@media (min-width:48rem){.n1ibjf0n{padding-inline:var(--timvir-page-margin);}}
.da95449{font-family:system-ui;}
.d1au8wkb{color:var(--timvir-text-color);font-size:0.8125rem;line-height:2.2;font-weight:400;cursor:pointer;min-height:36px;border-radius:8px;padding:0 12px;border:1px solid var(--timvir-border-color);background:var(--timvir-secondary-background-color);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.d1au8wkb:hover{background:var(--timvir-sidebar-highlight-color);}.d1au8wkb > svg{display:block;margin-right:8px;width:0.75rem;height:0.75rem;}
