.d1513p2s{display:grid;grid-template-columns:1fr;}
.b157mkz{--size:48px;z-index:1;position:absolute;top:0;right:0;overflow:hidden;width:var(--size);height:var(--size);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;outline:none;border:none;padding:6px;background:transparent;-webkit-transition:all 0.2s;transition:all 0.2s;cursor:pointer;pointer-events:none;opacity:0;}.b157mkz:hover{color:white;}.b157mkz:hover svg:first-child{-webkit-transform:translate(0,0);-ms-transform:translate(0,0);transform:translate(0,0);}.b157mkz:active svg:first-child{-webkit-transform:translate(2px,-2px);-ms-transform:translate(2px,-2px);transform:translate(2px,-2px);}
.b10oxtfo{pointer-events:all;opacity:1;}
.s1hdfi6o{position:absolute;z-index:-1;top:0;right:0;-webkit-transition:all 0.2s;transition:all 0.2s;-webkit-transform:translate(48px,-48px);-ms-transform:translate(48px,-48px);transform:translate(48px,-48px);}.s1hdfi6o path{fill:var(--c-p-4);}
.d17pltln{padding:16px 24px 16px 0;}

:root[data-timvir-theme="dark"] .r9v2r0l .shiki,:root[data-timvir-theme="dark"] .r9v2r0l .shiki span{color:var(--shiki-dark) !important;font-style:var(--shiki-dark-font-style) !important;font-weight:var(--shiki-dark-font-weight) !important;-webkit-text-decoration:var(--shiki-dark-text-decoration) !important;text-decoration:var(--shiki-dark-text-decoration) !important;}
.cs7tint{overflow-x:auto;contain:content;font-size:0.8em;border-radius:5px;--timvir-b-Code-bleed:calc(var(--timvir-margin,0px) * 0.6666);--timvir-b-Code-inlinePadding:max(var(--timvir-b-Code-bleed),8px);padding:0;margin:0 calc(-1 * var(--timvir-b-Code-bleed));border:1px solid var(--timvir-border-color);}.cs7tint pre{margin:0;padding:16px 0;background-color:var(--timvir-secondary-background-color) !important;}.cs7tint pre code{display:block;}.cs7tint pre code .line{display:inline-block;position:relative;width:100%;}.cs7tint pre .line{padding-inline:var(--timvir-b-Code-inlinePadding);}
.fjvaz2s{display:grid;border-radius:0;box-shadow:none;margin-inline:0;grid-auto-rows:min-content;grid-template-columns:[le] 0 [lc] 1fr [rc] 0 [re];grid-column-gap:16px;}.fjvaz2s:hover{box-shadow:none;}@media (min-width:60rem){.fjvaz2s{grid-template-columns:[le] 1fr [lc] minmax(0,48rem) [rc] 1fr [re];grid-column-gap:24px;}}.fjvaz2s > *{grid-column:lc / re;}
.lchll0h{padding-inline:var(--timvir-b-Code-inlinePadding);}
.h1xcko1i{background-color:#ffe10044;}:root[data-timvir-theme="dark"] .h1xcko1i{background-color:rgba(174,124,20,0.15);}
.ll2b9hx{display:inline-block;width:var(--timvir-b-Code-bleed);color:var(--timvir-secondary-text-color);text-align:right;padding-inline:4px;}
.c5vr6r2{font-size:0.8125rem;line-height:1.1875;color:var(--timvir-secondary-text-color);margin-top:0.3em;}
